<template>
    <Layout>
        <Header class="header">
            <Breadcrumb class="breadcrumb">
                <BreadcrumbItem to="/sys_manage/publishMember">发布对象</BreadcrumbItem>
                <BreadcrumbItem>修改</BreadcrumbItem>
            </Breadcrumb>
        </Header>
        <Content class="content">
            <Form ref="dataForm" :model="formData" :rules="rules" :label-width="200" >
                <FormItem label='姓名' prop="name">
                    <Input type="text" v-model="formData.name"/>
                </FormItem>
                <!-- phone -->
                <FormItem label="手机号码" prop="mobileNum">
                    <Input type="text" v-model="formData.mobileNum"/>
                </FormItem>
                <FormItem label="电话号码" prop="telNum">
                    <Input type="text" v-model="formData.telNum"/>
                </FormItem>
                <FormItem label="邮箱号码" prop="email">
                    <Input type="text" v-model="formData.email"/>
                </FormItem>
                <FormItem label="传真号码" prop="faxNum">
                    <Input type="text" v-model="formData.faxNum"/>
                </FormItem>
                <FormItem label="角色名称"  prop="roleId">
                    <Select v-model="formData.roleId">
                        <Option v-for="(item, index) in roleList" :key="index" :value="item.id">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="所属区域"  prop="areaCode">
                    <Select v-model="formData.areaCode">
                        <Option v-for="(item, index) in areaList" :key="index" :value="item.id">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="职务">
                    <Input type="text" v-model="formData.duty"/>
                </FormItem>
                <FormItem label="住址">
                    <Input type="text" v-model="formData.address"/>
                </FormItem>
                <FormItem>
                    <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
                </FormItem>  
            </Form>  
        </Content>
    </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
const validateMobileNum = (rule, value, callback) => {
    if (!value) {
        callback()
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback('请填写正确的手机号')
    } else {
        callback()
    }
}
const validateTelNum = (rule, value, callback) => {
    if (!value) {
        callback()
    } else if (!/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(value)) {
        callback('请填写正确的电话号码')
    } else {
        callback()
    }
}
const validateEmail = (rule, value, callback) => {
    if (!value) {
        callback()
    } else if (!/^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]*\.)+[A-Za-z]{2,14}$/.test(value)) {
        callback('请填写正确的邮箱号码')
    } else {
        callback()
    }
}
const validateFaxNum = (rule, value, callback) => {
    if (!value) {
        callback()
    } else if (!/^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/.test(value)) {
        callback('请填写正确的传真号码')
    } else {
        callback()
    }
}
export default Vue.extend({
    data() {
        return {
            id: null,
            formData: {},
            rules: {
                name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
                areaCode: [{ required: true, message: '请选择区域', trigger: 'change' }],
                mobileNum: [{ validator: validateMobileNum, trigger: 'blur'}],
                telNum: [{ validator: validateTelNum, trigger: 'blur'}],
                email: [{ validator: validateEmail, trigger: 'blur'}],
                faxNum: [{ validator: validateFaxNum, trigger: 'blur'}],
            },
            roleList: [],
            areaList: [],
        }
    },
    mounted() {
        // console.log(this.$route.params.id)
        this.id = this.$route.params.id
        Promise.all([
            this.getPublishRole(),
            this.getAreaList(),
        ]).then((e) => {
            this.getData()
        })
    },
    methods: {
        getData() {
            request.get(`/api/respond/respond_person/${this.id}`)
            .then((data) => {
                // console.log(data)
                this.formData = data
            })
        },
        getPublishRole() {
            return request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                // console.log(data)
                this.roleList = data
            })
        },
        getAreaList() {
            return request.get('/api/sys/area/descendants', null)
            .then((data) => {
                // console.log(data)
                const {current} = this.$store.state
                this.areaList = [...[{id: current.areaId, name: current.areaName}], ...data]
            })
        },
        submit() {
            const params = this.formData
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    request.put(`/api/respond/respond_person/${this.id}`, params)
                    .then((res) => {
                        this.$Message.success('修改成功!')
                        this.$router.replace('/sys_manage/publishMember')
                    })
                }
            })
        },
    },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    justify-content: center;
  }
  form {
    width: 650px;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
